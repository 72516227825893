import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetB2BLoginStaticComponentsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetB2BLoginStaticComponentsQuery = {
  __typename?: 'Query';
  pageB2BLogin: {
    __typename?: 'PageB2BLoginData';
    mainText: string;
    mainTitle: string;
  };
};

export const GetB2BLoginStaticComponentsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetB2BLoginStaticComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageB2BLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;

/**
 * __useGetB2BLoginStaticComponentsQuery__
 *
 * To run a query within a React component, call `useGetB2BLoginStaticComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BLoginStaticComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BLoginStaticComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetB2BLoginStaticComponentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetB2BLoginStaticComponentsQuery,
    GetB2BLoginStaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetB2BLoginStaticComponentsQuery,
    GetB2BLoginStaticComponentsQueryVariables
  >(GetB2BLoginStaticComponentsDocument, options);
}
export function useGetB2BLoginStaticComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetB2BLoginStaticComponentsQuery,
    GetB2BLoginStaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetB2BLoginStaticComponentsQuery,
    GetB2BLoginStaticComponentsQueryVariables
  >(GetB2BLoginStaticComponentsDocument, options);
}
export type GetB2BLoginStaticComponentsQueryHookResult = ReturnType<
  typeof useGetB2BLoginStaticComponentsQuery
>;
export type GetB2BLoginStaticComponentsLazyQueryHookResult = ReturnType<
  typeof useGetB2BLoginStaticComponentsLazyQuery
>;
export type GetB2BLoginStaticComponentsQueryResult = Apollo.QueryResult<
  GetB2BLoginStaticComponentsQuery,
  GetB2BLoginStaticComponentsQueryVariables
>;
