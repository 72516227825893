
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/b2b-login",
      function () {
        return require("private-next-pages/b2b-login.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/b2b-login"])
      });
    }
  