import type { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import {
  B2BForgotPassword,
  B2BLoginForm,
  B2BLoginLayout,
} from '@hotelplan/components.common.auth';
import { PageType } from '@hotelplan/graphql.types';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { ServerSideUnit } from '@hotelplan/libs.ssp-units';
import { createPageServerFn } from 'config/pageUnitsConfig';
import {
  GetB2BLoginMetaDataDocument,
  GetB2BLoginMetaDataQuery,
  GetB2BLoginMetaDataQueryVariables,
} from 'graphql/b2b/GetB2BLoginMetaData.generated';
import { useGetB2BLoginStaticComponentsQuery } from 'graphql/b2b/GetB2BLoginStaticComponents.generated';

const B2BLoginPage: NextPage = () => {
  const [
    isForgotPasswordOverlayShown,
    toggleForgotPasswordOverlay,
  ] = useState<boolean>(false);
  const [t] = useTranslation(['forms']);

  const { data, loading } = useGetB2BLoginStaticComponentsQuery();

  const { mainTitle, mainText } = data?.pageB2BLogin || {};

  return (
    <>
      <B2BLoginLayout
        mainTitle={mainTitle}
        mainText={mainText}
        loading={loading}
      >
        <B2BLoginForm
          onForgotPasswordClick={() => {
            toggleForgotPasswordOverlay(true);
          }}
        />
      </B2BLoginLayout>
      <B2BForgotPassword
        isShown={isForgotPasswordOverlayShown}
        onClose={() => {
          toggleForgotPasswordOverlay(false);
        }}
        forgotPasswordLabel={t('agentCode.forgotPassword.label')}
      />
    </>
  );
};

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.B2BLogin,
    pageEventType: `other`,
    namespaceRequired: [`common`, `auth`, `forms`],
  },
  [
    ServerSideUnit.createServerSideUnit(
      [apolloReqCtxUnitRef],
      async function getB2bLoginUnit(ctx, { queryCtx }) {
        const { data } = await queryCtx<
          GetB2BLoginMetaDataQuery,
          GetB2BLoginMetaDataQueryVariables
        >({
          query: GetB2BLoginMetaDataDocument,
        });

        return {
          props: {
            meta: data.pageB2BLogin.pageMetaData,
          },
        };
      }
    ),
  ] as any
);

export default B2BLoginPage;
